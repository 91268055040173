// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "bootstrap"

$(document).ready(function(){
  // Clipboard Tooltip
  $('.clipboard-btn').tooltip({
    trigger: 'click',
    placement: 'bottom'
  });

  $(function() {
    $('[data-toggle="tooltip"]').tooltip()
  })

  $(document).on("turbolinks:load", function(){
    $('[data-toggle="tooltip"]').tooltip();
  })

  function setTooltip(btn, message) {
    $(btn).tooltip('hide')
      .attr('data-original-title', message)
      .tooltip('show');
  }

  function hideTooltip(btn) {
    setTimeout(function() {
      $(btn).tooltip('hide');
    }, 1000);
  }

// Clipboard
  var clipboard = new Clipboard('.clipboard-btn');
  console.log(clipboard);

  clipboard.on('success', function(e) {
    setTooltip(e.trigger, 'Url copied to clipboard!');
    hideTooltip(e.trigger);
  });

});
